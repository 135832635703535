import { nanoid } from "nanoid"

// INDEX DATA
export const indexData = {
  title: "",
  name: "",
  subtitle: "",
  cta: "",
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: require("../images/projects/SmartBrain.png"),
    title: "Smart Brain",
    info:
      "Register a user and upload your photo. This webise will do face-recognition for you.",
    info2:
      "The website is hosted on AWS. The Front-end part is hosted by AWS S3 (buckets are able to host static websites). The Back-end part is hosted on EC2. PostgreSQL is selected as the database. ",
    url: "http://smartbrain2020.s3-website.eu-central-1.amazonaws.com",
    repo: "https://github.com/HaoMIN/facerecognitionbrain", // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: require("../images/projects/PortofolioWebsie.png"),
    title: "This Website",
    info:
      "Gatsby is used here for building this website. The content is generated based on React-bootstrap.",
    info2: "",
    url: "",
    repo: "https://github.com/HaoMIN/haogallary_gatsby", // if no repo, the button will not show up
  },
];

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: "instagram",
      url: "https://www.instagram.com/bennyminhao/",
    },
    {
      id: nanoid(),
      name: "linkedin",
      url: "https://www.linkedin.com/in/min-hao/",
    },
    {
      id: nanoid(),
      name: "github",
      url: "https://github.com/HaoMIN/haogallary_gatsby",
    },
  ],
}
