import React, { useState, useEffect } from "react"

import { Container, Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Image from "react-bootstrap/Image"
import Title from "../components/Title"
import Layout from "../components/layout"
import Contacts from "../components/Contacts"

import Projects from "./projects"

import "../components/aboutme.scss"

import { PortfolioProvider } from "../context/context"
import { projectsData, footerData } from "../data/data"

const AboutMe = () => {
  const [projects, setProjects] = useState([])
  const [footer, setFooter] = useState({})

  useEffect(() => {
    setProjects([...projectsData])
    setFooter({ ...footerData })
  }, [])

  return (
    <PortfolioProvider value={{ projects, footer }}>
      <Layout>
        <section id="about">
          <Title title="About Me" />

          <Container>
            <Row className="about-wrapper">
              <Col md={6} sm={12}>
                <Fade bottom duration={1000} delay={600} distance="30px">
                  <div className="about-wrapper__image">
                    <Image src={require("../images/profile.png")} />
                  </div>
                </Fade>
              </Col>
              <Col md={6} sm={12}>
                <Fade duration={1000} delay={1000} distance="30px">
                  <div className="about-wrapper__info">
                    <p className="about-wrapper__info-text">
                      {"Software Developer."}
                      <br />
                      {"Certified Scrum Master."}
                      <br /> {"Certified AWS Solution Architect."}
                    </p>
                    <p className="about-wrapper__info-text">
                      {
                        "Passionate about technology. Like travel. On the way to becoming a photographer."
                      }
                    </p>
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
        <Projects />
        <Contacts />
      </Layout>
    </PortfolioProvider>
  )
}

export default AboutMe
